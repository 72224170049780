import * as React from "react";
import { navigate } from "gatsby";
import { Layout, DefaultButton, Seo } from "../components";
import "../styles/screen.scss";
import {
  Heading,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  VStack,
  Text,
  Textarea,
} from "@chakra-ui/react";

import { StaticImage } from "gatsby-plugin-image";

const seoBundle = {
  title: "American Storage",
  subtitle: "Contact us",
  description: "Contact us for portable storage units",
  slug: "/contact",
};

const ContactPage = ({ location }) => {
  // const validationSchema = yup.object().shape({
  //   name: yup.string().required("Name is required"),
  //   email: yup.string().email().required("Email is required"),
  //   subject: yup.string().required("Subject is required"),
  //   message: yup.string().required("Message is required"),
  // });

  return (
    <Layout title={"Home"} location={location}>
      <Seo seoBundle={seoBundle} />
      <Flex
        maxW='full'
        h='100%'
        // header takes up 40/50 pixels
        pt={["150px", null, "150px"]}
        px={["20px", null, "100px"]}
        pb={["60px", null, "100px"]}
        bg={"pink"}
        align='center'
        justify='center'
        flexDirection='column'
      >
        <Heading
          as='h1'
          color='dkBlue'
          mb={["20px", null, null, "10px"]}
          mt='0px'
          fontWeight={800}
          fontSize={["3em", "4em"]}
          fontFamily='Lexend, sans-serif'
          lineHeight={["100%", null, null, "120%"]}
          textAlign='center'
        >
          Contact
        </Heading>
        <Flex
          mb='40px'
          mt='20px'
          flexDirection='row'
          justify='center'
          align='space-around'
        >
          <Flex
            flexDirection={["column", "row"]}
            justify='space-around'
            align='space-around'
          >
            <StaticImage
              src='../images/units.jpg'
              alt='American Storage Units'
              placeholder='blurred'
              layout='constrained'
              width={[300]}
            />
            <StaticImage
              src='../images/fac.jpg'
              alt='American Storage Facility'
              placeholder='blurred'
              layout='constrained'
              width={[300]}
            />
            <StaticImage
              src='../images/truck.jpg'
              alt='American Storage Truck'
              placeholder='blurred'
              layout='constrained'
              width={[300]}
            />
          </Flex>
        </Flex>
        <Text
          mt='20px'
          mb='7'
          textAlign='center'
          fontSize={20}
          maxW='800px'
          lineHeight='120%'
        >
          Have a question? Feel free to reach out.
        </Text>
        <Text
          mb='40px'
          fontSize={24}
          textAlign='center'
          maxW='800px'
          lineHeight='120%'
          fontWeight='bold'
        >
          Call us at <a href={`tel:2085393383`}>(208) 539-3383</a>. We will do
          our best to answer the phone or return your call shortly.
        </Text>
        {/* <Flex align='center' margin='auto' w='100%' justify='center'>
          <Formik
            initialValues={{
              name: "",
              email: "",
              subject: "",
              message: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { resetForm }) => {
              fetch("/", {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                body: encode({
                  "form-name": "contact-form",
                  ...data,
                }),
              })
                .then(() => {
                  resetForm();
                  navigate("/success");
                })
                .catch((e) => console.log(e));
            }}
          >
            {({ errors, touched }) => (
              <Form
                data-netlify='true'
                name='contact-form'
                data-netlify-honeypot='bot-field'
              >
                <VStack
                  w={["280px", "400px"]}
                  spacing={10}
                  bg='#fff'
                  p='20px'
                  align='flex-start'
                >
                  <Field type='hidden' name='form-name' />
                  <Field type='hidden' name='bot-field' />
                  <FormControl isInvalid={!!errors.name && touched.name}>
                    <FormLabel htmlFor='name' color='dkBlue'>
                      Name
                    </FormLabel>
                    <Field
                      as={Input}
                      id='name'
                      name='name'
                      type='name'
                      variant='filled'
                      h='42'
                      fontSize='20px'
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.email && touched.email}>
                    <FormLabel htmlFor='email' color='dkBlue'>
                      Email Address
                    </FormLabel>
                    <Field
                      as={Input}
                      id='email'
                      name='email'
                      type='email'
                      variant='filled'
                      h='42'
                      fontSize='20px'
                    />
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.subject && touched.subject}>
                    <FormLabel htmlFor='subject' color='dkBlue'>
                      Subject
                    </FormLabel>
                    <Field
                      as={Input}
                      id='subject'
                      name='subject'
                      type='subject'
                      variant='filled'
                      h='42'
                      fontSize='20px'
                    />
                    <FormErrorMessage>{errors.subject}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.message && touched.message}>
                    <FormLabel htmlFor='message' color='dkBlue'>
                      Message
                    </FormLabel>
                    <Field
                      as={Textarea}
                      id='message'
                      name='message'
                      type='message'
                      variant='filled'
                      fontSize='20px'
                    />
                    <FormErrorMessage>{errors.message}</FormErrorMessage>
                  </FormControl>
                  <DefaultButton
                    type='submit'
                    disabled={
                      !!errors.name ||
                      !!errors.email ||
                      !!errors.subject ||
                      !!errors.message
                    }
                    title='Submit'
                  ></DefaultButton>
                </VStack>
              </Form>
            )}
          </Formik>
        </Flex> */}
      </Flex>
    </Layout>
  );
};

export default ContactPage;
